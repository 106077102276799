// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-sites-pl-404-js": () => import("./../../../src/sites/PL/404.js" /* webpackChunkName: "component---src-sites-pl-404-js" */),
  "component---src-sites-pl-demo-cz-jsx": () => import("./../../../src/sites/PL/demo-cz.jsx" /* webpackChunkName: "component---src-sites-pl-demo-cz-jsx" */),
  "component---src-sites-pl-empik-jsx": () => import("./../../../src/sites/PL/empik.jsx" /* webpackChunkName: "component---src-sites-pl-empik-jsx" */),
  "component---src-sites-pl-en-jsx": () => import("./../../../src/sites/PL/en.jsx" /* webpackChunkName: "component---src-sites-pl-en-jsx" */),
  "component---src-sites-pl-index-js": () => import("./../../../src/sites/PL/index.js" /* webpackChunkName: "component---src-sites-pl-index-js" */),
  "component---src-sites-pl-payu-jsx": () => import("./../../../src/sites/PL/payu.jsx" /* webpackChunkName: "component---src-sites-pl-payu-jsx" */),
  "component---src-sites-pl-podzielna-4-jsx": () => import("./../../../src/sites/PL/podzielna4.jsx" /* webpackChunkName: "component---src-sites-pl-podzielna-4-jsx" */),
  "component---src-sites-pl-popup-jsx": () => import("./../../../src/sites/PL/popup.jsx" /* webpackChunkName: "component---src-sites-pl-popup-jsx" */),
  "component---src-sites-pl-psp-jsx": () => import("./../../../src/sites/PL/psp.jsx" /* webpackChunkName: "component---src-sites-pl-psp-jsx" */),
  "component---src-sites-pl-raty-0-jsx": () => import("./../../../src/sites/PL/raty0.jsx" /* webpackChunkName: "component---src-sites-pl-raty-0-jsx" */),
  "component---src-sites-pl-smartplan-jsx": () => import("./../../../src/sites/PL/smartplan.jsx" /* webpackChunkName: "component---src-sites-pl-smartplan-jsx" */),
  "component---src-sites-pl-ua-jsx": () => import("./../../../src/sites/PL/ua.jsx" /* webpackChunkName: "component---src-sites-pl-ua-jsx" */)
}

